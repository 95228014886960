
import Constructor from '~/components/catalog/constructor/index.vue';
import pageMeta from '~/mixins/pageMeta';

export default {
  components: { Constructor },
  mixins: [pageMeta],
  async asyncData({ $api, redirect, error }) {
    try {
      const { meta, landingWidget: constructor } = await $api.general.getPage()

      if (!meta || meta?.status > 400 || !constructor) {
        return error({ statusCode: meta?.status || 404 })
      }

      if (meta?.redirect) {
        return redirect(meta.status, meta.redirect)
      }

      return {
        pageTitle: meta?.title || '',
        pageMetaData: meta?.data || [],
        pageLinks: meta?.links || [],
        dynamicComponents: constructor?.data || [],
      };
    } catch (e) {
      error({ statusCode: 404  })
    }
  },
};
