
export default {
  name: 'Constructor',
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  created() {
    this.items?.forEach(item => {
      const { name } = item

      this.$options.components[name] = () => import(`@/components/elements/${name}.vue`)
    })
  },
}
